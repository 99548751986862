import React from 'react';

import '../button.css';

export default function Button({ children, ...props }) {
    return (<>
        {props.isInDevelopment ? <button className='button' disabled>In development</button> : <a className='project-link' id={props.contact ? "contact" : null} target="_blank" rel="noreferrer" href={props.url}>{children}</a>}
    </>
    )
}
