import React from 'react'
import '../navigation.css'

import { SiGithub, SiDribbble, SiBehance } from 'react-icons/si';
import { HiOutlineMail } from 'react-icons/hi';

export default function Navbar() {
    return (
        <nav>
            <div className='desktop'>
                <ul>
                    <li><a id='email' rel='noreferrer' href='mailto:hello@callumgiles.dev' alt="Email me" target="_blank"><HiOutlineMail /></a></li>
                    <li><a rel='noreferrer' href='https://github.com/ScotDev' alt="Github profile" target="_blank"><SiGithub /></a></li>
                    <li><a rel='noreferrer' href='https://dribbble.com/ScotDev' alt="Dribbble profile" target="_blank"><SiDribbble /></a></li>
                    <li><a rel='noreferrer' href='https://www.behance.net/scotdev' alt="Behance profile" target="_blank"><SiBehance /></a></li>
                </ul>
            </div>

            <div className='hamburger'>

                <input type='checkbox' ></input>
                <div className='hamburger-lines'>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <ul id="mobile-nav">
                    <li><a id='email' rel='noreferrer' href='mailto:callumgiles.dev' alt="Email me" target="_blank"><HiOutlineMail /></a></li>
                    <li><a rel='noreferrer' href='https://github.com/ScotDev' alt="Github profile" target="_blank"><SiGithub /></a></li>
                    <li><a rel='noreferrer' href='https://dribbble.com/ScotDev' alt="Dribbble profile" target="_blank"><SiDribbble /></a></li>
                    <li><a rel='noreferrer' href='https://www.behance.net/scotdev' alt="Behance profile" target="_blank"><SiBehance /></a></li>
                </ul>
            </div>
        </nav>
    )
}