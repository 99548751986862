import React from 'react';
import Button from './Button';
import { TbMail } from 'react-icons/tb';

export default function Header() {
    return (
        <header>
            <h2>Hi, I'm Callum Giles</h2>
            <h1>I make things for the web</h1>
            {/* <p className="tagline">Front-end development / UI Design</p> */}
            {/* <p className="skills">HTML/CSS/JS - React - Next.js - Figma</p> */}
            <Button contact url="mailto:hello@callumgiles.dev">Contact me <TbMail /></Button>
        </header>
    )
}
