import React from 'react';
import '../navigation.css';

import { SiGithub, SiDribbble, SiBehance } from 'react-icons/si';

export default function Footer() {
    return (
        <footer>
            <ul>
                <li><a rel='noreferrer' href='https://github.com/ScotDev' alt="Github profile" target="_blank"><SiGithub /></a></li>
                <li><a rel='noreferrer' href='https://dribbble.com/ScotDev' alt="Dribbble profile" target="_blank"><SiDribbble /></a></li>
                <li><a rel='noreferrer' href='https://www.behance.net/scotdev' alt="Behance profile" target="_blank"><SiBehance /></a></li>
            </ul>
            <a id='email' rel='noreferrer' href='mailto:hello@callumgiles.dev' alt="Behance profile" target="_blank">hello@callumgiles.dev</a>
        </footer>
    )
}
