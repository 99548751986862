import './App.css';
import CardsContainer from './components/CardsContainer';
import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';

import { Helmet } from "react-helmet";
// import ToggleMenu from './components/ToggleMenu';

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Portfolio site" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="icon" href="./favicon.ico"></link>
        <link rel="apple-touch-icon" href="./apple-touch-icon.png" />

        <link rel="canonical" href="http://www.callumgiles.dev" />
      </Helmet>
      <Navbar />
      <Header />
      <main>
        {/* <ToggleMenu /> */}
        <CardsContainer />
      </main>
      <Footer />
    </>
  );
}

export default App;
