import React from 'react';
import Card from './Card';
import { SiFigma, SiNextdotjs, SiChakraui, SiReact, SiStrapi } from 'react-icons/si';

export default function CardsContainer() {

    const portfolioItems = [
        { id: 0, CSSid: "brcodify", title: "brcodify.xyz", isInDevelopment: false, projectTypes: ["Website"], description: "A barcode generator. Printable and downloadable as .png", projectURL: "https://brcodify.netlify.app/", techTags: [[<SiReact />, "React"], [<SiChakraui />, "Chakra UI"]], imgURL: "https://i.imgur.com/V1uT6aH.png", imgOverflow: false },
        { id: 1, CSSid: "nagasaki", title: "Nagasaki knife company", isInDevelopment: false, projectTypes: ["UI Design"], description: "An online store for a Japanese bespoke kitchen knife manufacturer.", projectURL: "https://www.behance.net/gallery/124701831/Nagasaki-Knife-Company", techTags: [[<SiFigma />, "Figma"]], imgURL: "https://i.imgur.com/4pv3tHb.png", imgOverflow: true },
        { id: 2, CSSid: "marcereads", title: "marcereads.com", isInDevelopment: false, projectTypes: ["UI Design", "Website"], description: "A blogging and book review platform for an influencer, designed in Figma and built with Next.js and a headless CMS", projectURL: "https://marcereads-prod.vercel.app/", techTags: [[<SiFigma />, "Figma"], [<SiNextdotjs />, "Next.js"], [<SiStrapi />, "Strapi"]], imgURL: "https://i.imgur.com/TspiFNN.png", imgOverflow: false },
        { id: 3, CSSid: "weathr", title: "Weathr", isInDevelopment: true, projectTypes: ["UI Design"], description: "A simple weather app for mobile", projectURL: "https://dribbble.com/shots/18433723-Weathr", techTags: [[<SiFigma />, "Figma"]], imgURL: "../../assets/screenshots/weathr_4.png", imgOverflow: true }
    ]

    const cards = portfolioItems.map((item) => {
        return <Card key={item.id} {...item} />
    })

    return (
        <div className='cards-container'>
            {cards}
        </div>
    )
}

// https://i.imgur.com/19vOmoY.png
// 