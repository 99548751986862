import React from 'react';
import Button from './Button';
import Tag from './Tag';
import ProjectTypeTag from './ProjectTypeTag';
import { HiExternalLink } from 'react-icons/hi';

import '../card.css';

export default function Card(props) {

    const { CSSid, isInDevelopment, projectTypes, title, description, projectURL, techTags, imgURL, imgOverflow } = props;

    const projects = projectTypes.map((item, index) => {
        return (<ProjectTypeTag key={index}>{item}</ProjectTypeTag>)
    })


    const tags = techTags.map((item, index) => {
        return (<Tag key={index}>{item}</Tag>)
    })


    return (

        <article id={CSSid} className='card'>
            <div className='card-text-content'>
                <div className='project-tags-container'>{projects}</div>
                <h2>{title}</h2>
                <p>{description}</p>
                <Button type='external' isInDevelopment={isInDevelopment} url={projectURL}>View <HiExternalLink /></Button>
                <div className='tags-container'>
                    {tags}
                </div>
            </div>
            <img src={imgURL} className={imgOverflow ? "overflow" : null} alt="Project showcase" />
        </article>
    )
}
